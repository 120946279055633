import React, { useEffect } from 'react'
import Img from 'gatsby-image'
import Rellax from 'rellax'

import {
  TeamContentWrapper,
  Text,
  TeamContentBox,
  ImageContainer,
  Description,
  DescriptionTitle,
  DescriptionText,
  ExperienceQuote
} from './styles'

import Container from '../../UI/Container'

const TeamContent = ({ data }) => {
  const {
    quote,
    box_one: { title_box_one, text_box_one, image_box_one },
    box_two: { title_box_two, text_box_two, image_box_two },
    box_three: { title_box_three, text_box_three, image_box_three },
    box_four: { title_box_four, text_box_four, image_box_four },
    experience_quote
  } = data

  useEffect(() => {
    const rellax = new Rellax('.team-content', {
      center: true,
      vertical: true,
      horizontal: true
    })
  }, [])
  return (
    <TeamContentWrapper>
      <Container>
        <Text>{quote}</Text>
        <TeamContentBox>
          <Description
            className="team-content"
            data-rellax-min-y="-40"
            data-rellax-max-y="10"
            data-rellax-min-x="0"
            data-rellax-max-x="0"
            data-rellax-vertical-speed="-2"
            data-rellax-vertical-scroll-axis="y"
            data-rellax-mobile-speed="-4"
          >
            <DescriptionTitle width={'80%'}>{title_box_one}</DescriptionTitle>
            <DescriptionText width={'80%'} dangerouslySetInnerHTML={{ __html: text_box_one }} />
          </Description>
          <ImageContainer align="right" width="580">
            <Img fluid={image_box_one.localFile.childImageSharp.fluid} alt="" />
          </ImageContainer>
        </TeamContentBox>
        <TeamContentBox direction="reverse">
          <Description
            align={'flex-end'}
            className="team-content"
            data-rellax-min-y="-40"
            data-rellax-max-y="10"
            data-rellax-min-x="0"
            data-rellax-max-x="0"
            data-rellax-vertical-speed="-2"
            data-rellax-vertical-scroll-axis="y"
            data-rellax-mobile-speed="-4"
          >
            <DescriptionTitle width={'80%'}>{title_box_two}</DescriptionTitle>
            <DescriptionText width={'80%'} dangerouslySetInnerHTML={{ __html: text_box_two }} />
          </Description>
          <ImageContainer align="left" width="480">
            <Img fluid={image_box_two.localFile.childImageSharp.fluid} alt="" />
          </ImageContainer>
        </TeamContentBox>
        <TeamContentBox>
          <Description
            className="team-content"
            data-rellax-min-y="-40"
            data-rellax-max-y="10"
            data-rellax-min-x="0"
            data-rellax-max-x="0"
            data-rellax-vertical-speed="-2"
            data-rellax-vertical-scroll-axis="y"
            data-rellax-mobile-speed="-4"
          >
            <DescriptionTitle>{title_box_three}</DescriptionTitle>
            <DescriptionText dangerouslySetInnerHTML={{ __html: text_box_three }} />
          </Description>
          <ImageContainer align="right" width="480">
            <Img fluid={image_box_three.localFile.childImageSharp.fluid} alt="" />
          </ImageContainer>
        </TeamContentBox>
        <TeamContentBox direction="reverse">
          <Description
            align={'flex-end'}
            className="team-content"
            data-rellax-min-y="-40"
            data-rellax-max-y="10"
            data-rellax-min-x="0"
            data-rellax-max-x="0"
            data-rellax-vertical-speed="-2"
            data-rellax-vertical-scroll-axis="y"
            data-rellax-mobile-speed="-4"
          >
            <DescriptionTitle width={'80%'}>{title_box_four}</DescriptionTitle>
            <DescriptionText width={'80%'} dangerouslySetInnerHTML={{ __html: text_box_four }} />
          </Description>
          <ImageContainer align="left" width="580">
            <Img fluid={image_box_four.localFile.childImageSharp.fluid} alt="" />
          </ImageContainer>
        </TeamContentBox>
        <ExperienceQuote>
          <h2>{experience_quote.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: experience_quote.text }} />
        </ExperienceQuote>
      </Container>
    </TeamContentWrapper>
  )
}

export default TeamContent
