import React from 'react'
import Img from 'gatsby-image'
import { TeamIntroHero, Title, Overlay, Description, StyledImg } from './styles'

import Container from '../../UI/Container'

const TeamIntro = ({ data }) => {
  const { hero_image, intro, description } = data
  return (
    <>
      <TeamIntroHero>
        <Overlay />
        <StyledImg placeholderStyle={{ visibility: 'hidden' }} fluid={hero_image.localFile.childImageSharp.fluid} />
      </TeamIntroHero>
      <Container>
        <Title>{intro}</Title>
        <Description>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Description>
      </Container>
    </>
  )
}

export default TeamIntro
