import styled from 'styled-components'

export const TeamGridWrapper = styled.section`
  padding: 5rem 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100px;
    bottom: 0;
    transform: translateY(90%);

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
    }
  }

  @media screen and (max-width: 425px) {
    padding: 1rem 0;
  }
`

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: 800;
  line-height: 3.57rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10rem;

  @media screen and (max-width: 425px) {
    font-size: 2rem;
    font-weight: 800;
    line-height: 2.5rem;
    width: 90%;
    margin: 0 auto 6rem auto;
  }
`

export const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 10rem 4.6rem;
  justify-items: center;
  margin-bottom: 10rem;

  figure {
    width: 350px;
    height: 450px;
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
    border-radius: 4px;
    overflow: hidden;
    backface-visibility: hidden;

    figcaption {
      position: absolute;
      margin-top: 1.14rem;
    }

    .gatsby-image-wrapper {
      border-radius: 4px;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  @media only screen and (max-width: 1245px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10rem 3rem;

    figure {
      width: 75%;
      height: auto;
    }
  }

  @media only screen and (max-width: 768px) and (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10rem 2rem;
    figure {
      width: 100%;
    }
  }

  @media only screen and (max-width: 499px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7rem 1rem;
    figure {
      width: 100%;
    }
  }
`
export const Name = styled.h6`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.71rem;
  font-weight: 800;
  line-height: 2.14rem;

  @media only screen and (max-width: 499px) {
    font-size: 1.2rem;
  }
`
export const Role = styled.span`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: 1.71rem;

  @media only screen and (max-width: 590px) {
    display: flex;
    width: 190px;
    /* font-size: 0.8rem;
    line-height: 1rem; */
  }

  @media only screen and (max-width: 490px) {
    display: flex;
    width: 150px;
    font-size: 0.8rem;
    line-height: 1rem;
  }
`
