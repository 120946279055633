import styled from 'styled-components'
import Img from 'gatsby-image'

export const TeamIntroHero = styled.div`
  position: relative;
  width: 100%;
  height: 960px;

  @media only screen and (max-width: 1024px) {
    height: auto;
  }
`
export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #0d3151 0%, rgba(196, 196, 196, 0) 30%);
  z-index: 1;

  @media only screen and (max-width: 425px) {
    background: linear-gradient(180deg, #0d3151 0%, rgba(196, 196, 196, 0) 55%);
  }
`
export const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100px;
    bottom: -3px;

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 50%);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 50%);
    }
  }

  @media only screen and (max-width: 1024px) {
    height: auto;

    &::after {
      clip-path: polygon(0 100%, 100% 100%, 100% 90%, 0 70%);
    }
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: 800;
  line-height: 3.57rem;
  text-align: center;
  margin: 10rem auto;
  width: 80%;

  @media only screen and (max-width: 1024px) {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 6rem 0 3rem 0;
    width: 100%;
  }
`
export const Description = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8rem 0;

  div {
    flex-basis: 60%;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1.14rem;
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 4rem 0;

    div {
      flex-basis: 100%;
      margin-bottom: 3rem;
      text-align: left;
    }
  }
`
