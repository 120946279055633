import styled from 'styled-components'

export const TeamContentWrapper = styled.section`
  padding: 5rem 0;

  @media screen and (max-width: 425px) {
    padding: 1rem 0;
  }
`
export const Text = styled.p`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.14rem;
  font-weight: bold;
  line-height: 2.14rem;
  text-align: center;
  margin: 3rem 0 10rem 0;

  @media screen and (max-width: 425px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 3rem 0 7rem 0;
  }
`
export const TeamContentBox = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'reverse' ? 'row-reverse' : 'row')};
  padding: 6rem 0;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 50%;
  position: relative;
  justify-content: center;
  align-items: ${({ align }) => (align ? align : 'none')};
  z-index: 1;

  @media screen and (max-width: 1024px) {
    text-align: left;
  }
`

export const DescriptionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 3.57rem;
  font-weight: 800;
  line-height: 4.28rem;
  margin-bottom: 2.85rem;
  width: ${({ width }) => (width ? `${width}` : '100%')};

  @media only screen and (max-width: 1024px) {
    position: relative;
    width: 100%;
    font-size: 2.85rem;
    line-height: 3.57rem;

    left: 0;
  }
`

export const DescriptionText = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;
  width: ${({ width }) => (width ? `${width}` : '80%')};

  @media only screen and (max-width: 1024px) {
    width: 100%;
    text-align: left;
    margin-bottom: 3rem;
  }
`

export const ImageContainer = styled.figure`
  display: flex;
  flex-basis: 50%;
  justify-content: ${({ align }) => (align === 'left' ? 'flex-start' : 'flex-end')};
  width: 580px;
  max-height: 800px;

  img {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  .gatsby-image-wrapper {
    width: 100%;
    max-width: ${({ width }) => `${width}px`};
  }

  @media only screen and (max-width: 1024px) {
    justify-content: center;
    width: 100%;
  }
`
export const ExperienceQuote = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15rem 0;

  h2 {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: 4.28rem;
    font-weight: 800;
    line-height: 5.14rem;
    margin-bottom: 2rem;
  }

  div {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1.14rem;
    line-height: 1.71rem;
    padding: 2rem 0;
    text-align: center;
  }

  @media only screen and (max-width: 1024px) {
    padding: 4rem 0;

    h2 {
      font-size: 2.85rem;
      line-height: 3.57rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    div {
      width: 90%;
    }
  }
`
