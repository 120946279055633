import React from 'react'
import Img from 'gatsby-image'
import { TeamGridWrapper, Title, ImagesWrapper, Name, Role } from './styles'

import Container from '../../UI/Container'

const TeamGrid = ({ data }) => {
  const { title, employees } = data

  const ProfileBox = ({ employee }) => {
    const {
      name,
      role,
      image: {
        localFile: {
          childImageSharp: { fluid }
        }
      }
    } = employee
    return (
      <figure>
        <Img fluid={fluid} alt="" className="employee-image" />
        <figcaption>
          <Name>{name}</Name>
          <Role>{role}</Role>
        </figcaption>
      </figure>
    )
  }

  return (
    <TeamGridWrapper>
      <Container>
        <Title>{title}</Title>
        <ImagesWrapper>
          {employees.map((employee) => (
            <ProfileBox key={employee.image.id} employee={employee} />
          ))}
        </ImagesWrapper>
      </Container>
    </TeamGridWrapper>
  )
}

export default TeamGrid
